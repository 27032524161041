<template>
  <div class="not-found">
    <el-col class="not-found-card" :border="false">
      <h1 class="not-found-title">404 - 页面未找到</h1>
      <p class="not-found-description">很抱歉，您访问的页面不存在。</p>
      <el-button type="primary" @click="goHome">返回首页</el-button>
    </el-col>
  </div>
</template>

<script>
export default {
  name: "404Page",
  methods: {
    goHome() {
      this.$router.push("/user");
    },
  },
};
</script>

<style scoped>
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.not-found-card {
  text-align: center;
  padding: 20px;
  width: 360px;
  background-color: #fff;
  /* border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.not-found-title {
  font-size: 36px;
  margin-bottom: 10px;
  color: #f56c6c; /* 自定义颜色 */
}

.not-found-description {
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
}
</style>
