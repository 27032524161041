<template>
  <div></div>
</template>

<script>
import { login } from "@/api/login";
import * as dd from "dingtalk-jsapi";

export default {
  name: "dingLogin",
  data() {
    return {};
  },
  mounted() {
    // 在页面加载时检查是否在钉钉中，并且直接获取用户信息
    this.checkAndFetchDingTalkUserInfo();
  },
  methods: {
    checkAndFetchDingTalkUserInfo() {
      // 判断是否在钉钉中
      const isDingTalk = /DingTalk/i.test(navigator.userAgent);

      if (isDingTalk) {
        // 在钉钉中，调用相应API获取用户信息
        this.getDingTalkUserInfo();
      }
    },
    getDingTalkUserInfo() {
      // 可以通过 DingTalk API 获取用户信息，更新本地存储，然后跳转到对应页面
      dd.ready(
        function () {
          dd.runtime.permission.requestAuthCode({
            corpId: "ding256bb98cdcc8b87635c2f4657eb6378f", // 替换为实际的corpId
            onSuccess: (info) => {
              // 向后端发送请求以获取用户信息
              this.fetchUserInfoFromBackend(info.code);
            },
            onFail: (err) => {
              console.log(JSON.stringify(err, null, 2));
              alert(JSON.stringify(err, null, 2));
            },
          });
        }.bind(this)
      );
    },
    fetchUserInfoFromBackend(code) {
      const obj = {
        username: "",
        password: "",
        remember: 1,
        code: code,
      };
      // 向后端发送请求
      login(obj)
        .then((res) => {
          // 处理登录成功的情况
          if (res.success) {
            this.$message.success("登录成功");
            const { token } = res.data;
            // 使用本地存储将令牌和用户信息持久化
            localStorage.setItem("ACCESS_TOKEN", token);
            localStorage.setItem("USER_INFO", JSON.stringify(res.data));
            localStorage.setItem("LASTING", this.rememberValue);

            this.$router.push("m-homework");
          } else {
            console.log("err", res);
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          // 处理登录失败的情况
          this.$message({
            message: err.message,
            type: "error",
          });
        });
    },
  },
};
</script>

<style></style>
