<template>
  <el-card :bordered="false" class="header">
    <el-row>
      <el-col :span="4">
        <div @click="toggleCollapse">
          <el-button style="border: none">
            <el-icon v-if="!isCollapse" style="font-size: 30px">
              <Fold />
            </el-icon>
            <el-icon v-else style="font-size: 30px">
              <Expand />
            </el-icon>
          </el-button>
        </div>
      </el-col>
      <el-col :span="4" :offset="16">
        <el-dropdown style="top: 10px">
          <span @click="chancelUser" class="username"
            >用户名：{{ userInfo.username }}</span
          >
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="a" @click="savePwd"
                >修改密码</el-dropdown-item
              >
              <el-dropdown-item command="b" @click="logOut"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <el-dialog v-model="diaSavePwd" :title="pwdTitle" width="30%">
          <SavePassword ref="SavePassword" />

          <!-- <template #footer>
            <span class="dialog-footer">
              <el-button type="primary" @click="diaSavePwd = false">
                确认
              </el-button>
              <el-button @click="diaSavePwd = false">取消</el-button>
            </span>
          </template> -->
        </el-dialog>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import SavePassword from "@/views/login/modules/SavePassword";
// import IdleTimerMixin from "@/utils/idleTimerMixin"; // 导入全局的无操作计时器 mixin
import { mapState } from "vuex";

export default {
  // mixins: [IdleTimerMixin], // 混入全局的无操作计时器逻辑

  data() {
    return {
      userInfo: {},
      diaSavePwd: false,
      pwdTitle: "修改密码",
      isCollapse: false,
    };
  },
  created() {
    // 尝试从 localStorage 中获取用户信息
    const userInfoStr = localStorage.getItem("USER_INFO");
    if (userInfoStr) {
      this.userInfo = JSON.parse(userInfoStr);
    }
  },
  components: {
    SavePassword, // 注册 组件
  },
  computed: {
    ...mapState(["isCollapse"]),
  },
  methods: {
    savePwd() {
      this.diaSavePwd = true;
      this.$nextTick(() => {
        if (this.$refs.SavePassword) {
          this.$refs.SavePassword.loadData(this.userInfo);
        }
      });
      console.log("save");
    },
    logOut() {
      // 清除本地存储中的 token
      localStorage.removeItem("ACCESS_TOKEN");
      localStorage.removeItem("USER_INFO");

      // 在 Vuex 中清除 token（如果你的 token 也存储在 Vuex 中）
      this.$store.commit("SET_ACCESS_TOKEN", null);

      // 其他清除操作，例如重定向到登录页面等
      this.$message.success("退出成功");
      // 你可以使用路由器（router）进行页面跳转
      // 停止计时器
      // this.stopUserInactiveTimer();
      this.$router.push("/login");

      console.log("logOut");
    },
    // toggleCollapse() {
    //   this.isCollapse = !this.isCollapse;
    // },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
      this.$store.commit("setCollapse", this.isCollapse);
    },
  },
};
</script>

<style scoped>
.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}
.username {
  font-weight: bold;
  font-size: 16px;
}
</style>
