<template>
  <div class="password-change">
    <el-form
      ref="passwordForm"
      :model="queryParam"
      :rules="passwordRules"
      label-width="80px"
      size="default"
    >
      <el-form-item label="旧密码">
        <el-input
          v-model="queryParam.oldPassword"
          show-password
          type="password"
          auto-complete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码">
        <el-input
          v-model="queryParam.newPassword"
          show-password
          type="password"
          auto-complete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码">
        <el-input
          v-model="queryParam.confirmPassword"
          show-password
          type="password"
          auto-complete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="changePassword">修改密码</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { savePwd } from "@/api/login";

export default {
  data() {
    return {
      queryParam: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      passwordRules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, message: "密码长度不能少于6位", trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          {
            validator: this.validateConfirmPassword,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    loadData(userInfo) {
      this.userInfo = userInfo;
    },
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.queryParam.newPassword) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    },
    changePassword() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          const { newPassword, oldPassword } = this.queryParam;
          const obj = {
            original_password: oldPassword,
            password: newPassword,
          };
          console.log(this.userInfo);
          savePwd(obj)
            .then((res) => {
              if (res.success) {
                // 清除本地存储中的 token
                localStorage.removeItem("ACCESS_TOKEN");
                localStorage.removeItem("USER_INFO");

                this.$store.commit("SET_ACCESS_TOKEN", null);

                this.$message.success("密码修改成功");
                this.$router.push("/login");
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              // 处理登录失败的情况
              this.$message({
                message: err.message,
                type: "error",
              });
              this.confLoading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.password-change {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}
</style>
