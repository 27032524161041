// store/index.js

import Vuex from 'vuex';

export default new Vuex.Store({
  state: {
    ACCESS_TOKEN: localStorage.getItem('ACCESS_TOKEN') || '', // 从本地存储中获取令牌
    isCollapse: false, // 初始状态为非折叠
    // 其他状态属性...
  },
  mutations: {
    SET_ACCESS_TOKEN(state, token) {
      state.ACCESS_TOKEN = token;
      // 同时将令牌保存到本地存储中
      localStorage.setItem('ACCESS_TOKEN', token);
    },
     setCollapse(state, value) {
       state.isCollapse = value;
    },
    // 其他 mutations...
  },
  actions: {
    // 异步操作，可选
  },
  getters: {
    // 计算属性，可选
  },
  modules: {
    // 可以包含其他模块，可选
  }
});
