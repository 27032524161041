<template>
  <el-container>
    <el-aside class="menu-aside transition-menu" :style="asideStyle">
      <el-menu
        default-active="2"
        :collapse="isCollapse"
        :default-openeds="openeds"
        active-text-color="#fff"
        background-color="#000"
        class="el-menu-vertical"
        text-color="#fff"
        router
        style="height: 100%"
      >
        <h3
          class="mb-2"
          style="margin: 0 auto; text-align: center; margin-bottom: 40px"
        >
          <span
            style="
              top: 20px;
              position: relative;
              color: #fff;
              font-weight: bold;
            "
            >音乐究极院 数据管理后台
          </span>
        </h3>
        <el-sub-menu
          v-for="(route, index) in filteredRoutes"
          :key="index"
          :index="route.path"
        >
          <template #title>
            <el-icon>
              <component :is="route.icon"></component>
              <!-- 使用组件名称 -->
            </el-icon>
            <span>{{ route.name }}</span>
          </template>
          <el-menu-item
            v-for="(childRoute, childIndex) in route.children"
            :key="childIndex"
            :index="childRoute.path"
            @click="handleSubMenuOpen(childRoute.path)"
          >
            {{ childRoute.name }}
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header style="min-width: 100vh; padding: 0; height: auto">
        <GlobalPageHeader />
      </el-header>
      <el-main style="min-width: 100vh; overflow-y: auto">
        <div style="height: 100vh">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script setup>
import dynamicRoutes from "../router/dynamic-routes";
import { computed, ref, onMounted, watch } from "vue";
import store from "@/store";

const userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
const userType = userInfo.type;
const openeds = ref([]); // 默认为空数组
const isCollapse = computed(() => {
  return store.state.isCollapse;
});
// const lSpan = computed(() => {
//   if (store.state.isCollapse == false) {
//     return 3;
//   } else {
//     return 1;
//   }
// });
// const rSpan = computed(() => {
//   if (store.state.isCollapse == false) {
//     return 21;
//   } else {
//     return 23;
//   }
// });
if (userType === 1 && userType != null) {
  openeds.value = ["/user"]; // 当 userType 为 1 时设置为包含 "/user" 的数组
}

// 从 localStorage 中检索 openeds 值
const storedOpeneds = localStorage.getItem("openeds");
if (storedOpeneds) {
  openeds.value = JSON.parse(storedOpeneds);
}
const route = ref(); // 获取当前路由信息

onMounted(() => {
  // 恢复选中状态
  const storedRoute = localStorage.getItem("route");
  if (storedRoute) {
    route.value = storedRoute;
  }
  console.log("currentPath", route);
  if (!openeds.value.includes(route)) {
    openeds.value.push(route);
  }

  // 监听 openeds 的变化，当它发生变化时保存到 localStorage
  watch(openeds, (newValue) => {
    localStorage.setItem("openeds", JSON.stringify(newValue));
  });
});

const filteredRoutes = computed(() => {
  if (userType === 1) {
    return dynamicRoutes;
  } else if (userType === 2) {
    // 当 userType 为 2 时，过滤掉 "user" 页面
    // return dynamicRoutes.filter(
    //   (route) =>
    //     route.path !== "/dashboard" &&
    //     route.path !== "/product" &&
    //     route.path !== "/order"
    // );
    return dynamicRoutes.filter(
      (route) => route.path.includes("/user") || route.path.includes("/channel")
    );
  } else if (userType === 3) {
    return dynamicRoutes.filter(
      (route) => route.path.includes("/user") || route.path.includes("/channel")
    );
  } else if (userType === 4) {
    return dynamicRoutes.filter((route) => route.path.includes("/user"));
  }
  return dynamicRoutes;
});
const handleSubMenuOpen = (path) => {
  openeds.value = [path];
  route.value = path;
  console.log(path);
};

// 计算 el-aside 的样式
const asideStyle = computed(() => {
  return {
    // 设置 el-aside 的其他样式，例如背景色、高度等
    width: isCollapse.value ? "65px" : "260px", // 控制宽度
  };
});
</script>

<style scoped>
.el-menu {
  border-right: none;
}
/* 选中状态的样式 */
.el-menu-item.is-active {
  background-color: #1890ff; /* 设置选中状态的背景色为蓝色 */
  color: #fff; /* 设置选中状态的文本颜色 */
}
/* 定义过渡效果 */
.transition-menu {
  transition: width 0.3s; /* 设置过渡时间为0.3秒，可以根据需要调整 */
}
</style>
