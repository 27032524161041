import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import store from '@/store'; // 导入 Vuex Store
import GlobalPageHeader from './components/GlobalPageHeader.vue';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import router from './router'; // 导入路由配置
import axiosInstance from './utils/axios'; // 引入axios.js文件
import './assets/global-styles.css'; // 引入全局样式文件
import zhCN from "element-plus/dist/locale/zh-cn.mjs" //引入中文

const app = createApp(App);
// 将Axios实例挂载到Vue的原型上，使它可以在所有组件中使用
app.config.globalProperties.$axios = axiosInstance;
app.use(router);
app.component('GlobalPageHeader', GlobalPageHeader);
app.use(ElementPlus, {locale:zhCN})
app.use(store); // 将 Vuex Store 添加到应用程序
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.mount('#app');
