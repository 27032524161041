// request.js

import axios from 'axios';
import { logoutFunction } from '@/utils/logout.js'; // 请替换为实际的文件路径


// 创建一个axios实例
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000, // 请求超时时间
});

instance.interceptors.response.use(
  (response) => {
    // 修改响应拦截器，直接返回data属性的值
    if (response.data && response.data.code == 400) {
      // 如果响应的状态码为 400，则拒绝请
      logoutFunction(); // 调用 logoutFunction 函数
      return response.data;
    } else {
      return response.data;
    }

  },
  (error) => {
    // 处理响应错误
    return Promise.reject(error);
  }
);

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': '*/*'
    }
    config.headers = { ...headers, ...config.headers }
    // 获取 Token（假设你的 Token 存储在本地存储中）
    const token = localStorage.getItem('ACCESS_TOKEN'); // 请替换为实际的 Token 存储键名

    // 如果存在 Token，则将它添加到请求头中
    if (token) {
      config.headers['token'] = token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 导出自定义的axios实例
export default instance;
