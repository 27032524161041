<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import IdleTimerMixin from "@/utils/idleTimerMixin"; // 导入全局的无操作计时器 mixin

export default {
  // mixins: [IdleTimerMixin], // 混入全局的无操作计时器逻辑

  name: "App",
  components: {},
};
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
